<template>
  <div>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form v-loading="loading" label-width="10rem">
            <el-form-item label="红包最小金额">
              <div>
                <el-input-number v-model="form.award_min" :min="0.01"></el-input-number>元
              </div>
              <div class="y-desc">根据商户平台设置配置此参数，以免无法完成红包的发放</div>
              <div class="y-desc">此数额将显示在商户发红包界面</div>
              <div class="y-desc">此数额*红包个数小于商户发放红包总额时将拦截商户的发红包操作</div>
            </el-form-item>
            <el-form-item label="发红包手续费">
              <el-input-number v-model="form.red_packet_rate" :min="0"></el-input-number>%
            </el-form-item>
            <el-form-item label="上传视频大小限制">
              <el-input-number v-model="form.video_limit" :min="1"></el-input-number>M
            </el-form-item>
            <el-form-item label="视频解析按钮文本">
              <el-input v-model="form.video_parse_btn"></el-input>
              <div class="y-desc" style="line-height: 1rem">按钮文本为空时不显示</div>
            </el-form-item>
            <el-form-item label="视频解析接口TOKEN">
              <el-input v-model="form.video_parse_token"></el-input>
            </el-form-item>
            <el-form-item label="百度ai应用">
              须使用 百度ai 短语音识别极速版
            </el-form-item>
            <el-form-item label="百度ai应用 API Key">
              <el-input v-model="form.bdai_client_id"></el-input>
            </el-form-item>
            <el-form-item label="百度ai应用 Secret Key">
              <el-input v-model="form.bdai_client_secret"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "setting",
  data(){
    return{
      loading:false,
      form:{
        award_min:0,
        red_packet_rate:0,
        video_parse_btn:"",
        video_limit:0,
        video_parse_token:"",
        bdai_client_id:"",
        bdai_client_secret:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    submit(){
      this.$api.plugin.voiceRedPacket.optEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.plugin.voiceRedPacket.opt().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>
  .y-desc{
    line-height: 1rem;
  }
</style>